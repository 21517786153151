import React, { Component, Fragment } from "react";
import { Col, Container, Row, Card, Button, Form } from "react-bootstrap";
class UserOnboard extends Component {
  render() {
    return (
      <Fragment>
        <Container fluid={true} className="TopSection">
          <Row className="d-flex p-1 justify-content-center">
            <Col className="shadow-sm bg-white mt-2" md={6} lg={6} sm={8} xs={12}>
              <Card>
                <Card.Body>
                  <h4 className="section-title">User Sing In</h4>
                  <Form>
                      <Form.Label>Please Enter Your Mobile Number</Form.Label>
                      <Form.Control type="email" placeholder="your mobile number" />
                  </Form>
                  <Button className="btn btn-block m-2 site-btn" >Next</Button>
                </Card.Body>
              </Card>
            </Col>

            <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
            <img className="onboardBanner" src="/images/onboard2.jpg" alt="login banner"/>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default UserOnboard;
