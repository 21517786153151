import React, {Component} from 'react';

class MegaMenuMobile extends Component {
    constructor() {
        super();
        this.MegaMenu=this.MegaMenu.bind(this);
    }

    componentDidMount() {
        this.MegaMenu();
    }
    MegaMenu(){
        var acc=document.getElementsByClassName("accordionMobile");
        var accNum=acc.length;
        var i;
        for(i=0;i<accNum;i++) {
            acc[i].addEventListener("click", function (){
                this.classList.toggle("active");
                var panel=this.nextElementSibling;
                if(panel.style.maxHeight){
                    panel.style.maxHeight=null;
                }
                else{
                    panel.style.maxHeight=panel.scrollHeight+ "px"
                }
            })
        }
    }

    render() {
        return (
            <div className="accordionMenuDivMobile">
                <div className="accordionMenuDivInsideMobile">
                <button className="accordionMobile"><img className="accordionMenuIconMobile" src="https://img.freepik.com/free-icon/t-shirt-thin-outline_318-32932.jpg?w=2000"/>Men's Clothing</button>
                  <div className="panelMobile">
                    <ul>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                    </ul>
                  </div>
                  <button className="accordionMobile"><img className="accordionMenuIconMobile" src="https://img.freepik.com/free-icon/t-shirt-thin-outline_318-32932.jpg?w=2000"/>Men's Clothing</button>
                  <div className="panelMobile">
                    <ul>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                    </ul>
                  </div><button className="accordionMobile"><img className="accordionMenuIconMobile" src="https://img.freepik.com/free-icon/t-shirt-thin-outline_318-32932.jpg?w=2000"/>Men's Clothing</button>
                  <div className="panelMobile">
                    <ul>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                    </ul>
                  </div><button className="accordionMobile"><img className="accordionMenuIconMobile" src="https://img.freepik.com/free-icon/t-shirt-thin-outline_318-32932.jpg?w=2000"/>Men's Clothing</button>
                  <div className="panelMobile">
                    <ul>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                        <li><a href="#" className="accordionItemMobile">Mans Shirt</a></li>
                    </ul>
                  </div><button className="accordionMobile"><img className="accordionMenuIconMobile" src="https://img.freepik.com/free-icon/t-shirt-thin-outline_318-32932.jpg?w=2000"/>Men's Clothing</button>
             

                </div>


            </div>
        );
    }
}

export default MegaMenuMobile;