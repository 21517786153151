import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";

class Favourite extends Component {
    componentDidMount() {
        window.scroll(0, 0);
      }
    render() {
    return (
            <Fragment>
            <Container className="text-center card" fluid="true">
                <h4 >My Favourite Items</h4>
                <h6>Some Of Our Exclusive Collection, You May Like</h6>
                <Row>
                    <Col key={0} xl={3} lg={3} md={3} sm={6} xs={6} >
                        <Card className="image-box card text-center">
                            <img src="images/product2.jpg"/>
                            <Card.Body>
                                <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                <p className="product-price-on-card">৳ 9,691</p>
                                <Button className='btn btn-sm site-btm'><i className='fa fa-trash-alt'>Remove</i></Button>
                            </Card.Body>
                        </Card>
                    </Col>   <Col key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                        <Card className="image-box card text-center">
                            <img src="images/product2.jpg"/>
                            <Card.Body>
                                <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                <p className="product-price-on-card">৳ 9,691</p>
                                <Button className='btn btn-sm site-btm'><i className='fa fa-trash-alt'>Remove</i></Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col key={2} xl={3} lg={3} md={3} sm={6} xs={6} >
                        <Card className="image-box card text-center">
                            <img src="images/product2.jpg"/>
                            <Card.Body>
                                <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                <p className="product-price-on-card">৳ 9,691</p>
                                <Button className='btn btn-sm site-btm'><i className='fa fa-trash-alt'>Remove</i></Button>
                            </Card.Body>
                        </Card>
                    </Col>   <Col key={3} xl={3} lg={3} md={3} sm={6} xs={6} >
                        <Card className="image-box card text-center">
                            <img src="images/product2.jpg"/>
                            <Card.Body>
                                <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                <p className="product-price-on-card">৳ 9,691</p>
                                <Button className='btn btn-sm site-btm'><i className='fa fa-trash-alt'>Remove</i></Button>
                            </Card.Body>
                        </Card>
                    </Col><Col key={4} xl={3} lg={3} md={3} sm={6} xs={6} >
                        <Card className="image-box card text-center">
                            <img src="images/product2.jpg"/>
                            <Card.Body>
                                <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                <p className="product-price-on-card">৳ 9,691</p>
                                <Button className='btn btn-sm site-btm'><i className='fa fa-trash-alt'>Remove</i></Button>
                            </Card.Body>
                        </Card>
                    </Col><Col key={5} xl={3} lg={3} md={3} sm={6} xs={6} >
                        <Card className="image-box card text-center">
                            <img src="images/product2.jpg"/>
                            <Card.Body>
                                <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                <p className="product-price-on-card">৳ 9,691</p>
                                <Button className='btn btn-sm site-btm'><i className='fa fa-trash-alt'>Remove</i></Button>
                            </Card.Body>
                        </Card>
                    </Col><Col key={6} xl={3} lg={3} md={3} sm={6} xs={6} >
                        <Card className="image-box card text-center">
                            <img src="images/product2.jpg"/>
                            <Card.Body>
                                <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                <p className="product-price-on-card">৳ 9,691</p>
                                <Button className='btn btn-sm site-btm'><i className='fa fa-trash-alt'>Remove</i></Button>
                            </Card.Body>
                        </Card>
                    </Col><Col key={7} xl={3} lg={3} md={3} sm={6} xs={6} >
                        <Card className="image-box card text-center">
                            <img src="images/product2.jpg"/>
                            <Card.Body>
                                <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                <p className="product-price-on-card">৳ 9,691</p>
                                <Button className='btn btn-sm site-btm'><i className='fa fa-trash-alt'>Remove</i></Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
        );
    }
}

export default Favourite;