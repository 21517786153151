import React, { Component, Fragment } from "react";
import { Col, Container, Row,  Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class CartList extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  
  state = {
      value1: 4, // initial value of the input
      value2: 3, // initial value of the input
      value3: 3, // initial value of the input
      value4: 3, // initial value of the input
    };

  handleInputChange1 = (event) => {
      this.setState({ value1: event.target.value }); // update the value of the input whenever it changes
    };
 handleInputChange2 = (event) => {
      this.setState({ value2: event.target.value }); // update the value of the input whenever it changes
    };
 handleInputChange3 = (event) => {
      this.setState({ value3: event.target.value }); // update the value of the input whenever it changes
    };
 handleInputChange4 = (event) => {
      this.setState({ value4: event.target.value }); // update the value of the input whenever it changes
    };

  render() {
    return (
      <Fragment>
        <Container className="text-center card" fluid={true}>
          <Row>
            <Col
              className="p-1 text-center"
              md={12}
              lg={12}
              sm={12}
              xs={12}
              style={{ marginTop: "52px" }}
            >
              <Card>
                <Card.Body>
                  <Row>
                    <label className="form-label">Product Cart List</label>
                    <h4 className="">
                      Total Item = 05 | Total Payment = 500TK
                    </h4>
                    <Col className="p-1">
                      <Link to="/order" className="btn btn-block mt-3 site-btn">
                        <i class="fas fa-shopping-cart checkout-icon"></i>Check
                        Out Now
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <img className="w-100" src="images/product1.jpg" />

                      <h5 className="Product-Name">
                        Asus Tuf A15 FA506IU Ryzen 7 4800H GTX
                      </h5>
                      <h6 className="">Total Price:3000TK</h6>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <Button className="btn btn-block site-btn">
                            <i className="fa fa-trash-alt mr-2"></i>
                          </Button>
                        </div>
                        <div className="col-6">
                          <input
                            value={this.state.value1}
                            className="form-control text-center"
                            type="number"
                            step="1"
                            min="0"
                            onChange={this.handleInputChange1}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <img className="w-100" src="images/product1.jpg" />

                      <h5 className="Product-Name">
                        Asus Tuf A15 FA506IU Ryzen 7 4800H GTX
                      </h5>
                      <h6 className="">Total Price:3000TK</h6>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <Button className="btn btn-block site-btn">
                            <i className="fa fa-trash-alt mr-2"></i>Remove
                          </Button>
                        </div>
                        <div className="col-6">
                        <input
                            value={this.state.value2}
                            className="form-control text-center"
                            type="number"
                            step="1"
                            min="0"
                            onChange={this.handleInputChange2}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <img className="w-100" src="images/product1.jpg" />

                      <h5 className="Product-Name">
                        Asus Tuf A15 FA506IU Ryzen 7 4800H GTX
                      </h5>
                      <h6 className="">Total Price:3000TK</h6>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <Button className="btn btn-block site-btn">
                            <i className="fa fa-trash-alt mr-2"></i>Remove
                          </Button>
                        </div>
                        <div className="col-6">
                        <input
                            value={this.state.value3}
                            className="form-control text-center"
                            type="number"
                            step="1"
                            min="0"
                            onChange={this.handleInputChange3}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> 
            <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <img className="w-100" src="images/product1.jpg" />

                      <h5 className="Product-Name">
                        Asus Tuf A15 FA506IU Ryzen 7 4800H GTX
                      </h5>
                      <h6 className="">Total Price:3000TK</h6>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <Button className="btn btn-block site-btn">
                            <i className="fa fa-trash-alt mr-2"></i>Remove
                          </Button>
                        </div>
                        <div className="col-6">
                        <input
                            value={this.state.value4}
                            className="form-control text-center"
                            type="number"
                            step="1"
                            min="0"
                            onChange={this.handleInputChange4}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <img className="w-100" src="images/product1.jpg" />

                      <h5 className="Product-Name">
                        Asus Tuf A15 FA506IU Ryzen 7 4800H GTX
                      </h5>
                      <h6 className="">Total Price:3000TK</h6>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <Button className="btn btn-block site-btn">
                            <i className="fa fa-trash-alt mr-2"></i>Remove
                          </Button>
                        </div>
                        <div className="col-6">
                        <input
                            value={this.state.value4}
                            className="form-control text-center"
                            type="number"
                            step="1"
                            min="0"
                            onChange={this.handleInputChange4}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <img className="w-100" src="images/product1.jpg" />

                      <h5 className="Product-Name">
                        Asus Tuf A15 FA506IU Ryzen 7 4800H GTX
                      </h5>
                      <h6 className="">Total Price:3000TK</h6>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <Button className="btn btn-block site-btn">
                            <i className="fa fa-trash-alt mr-2"></i>Remove
                          </Button>
                        </div>
                        <div className="col-6">
                        <input
                            value={this.state.value4}
                            className="form-control text-center"
                            type="number"
                            step="1"
                            min="0"
                            onChange={this.handleInputChange4}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default CartList;
