import React, { Component, Fragment } from "react";
import { Button, Col, Container, Form, Row, Card } from "react-bootstrap";
import validation from "../../validation/validation";
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Contact extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      mobile: "",
      msg: "",
    };
  }
  nameOnChange = (event) => {
    let name = event.target.value;
    // alert(name);
    this.setState({ name: name });
  };
  mobileOnChange = (event) => {
    let mobile = event.target.value;
    this.setState({ mobile: mobile });
  };
  msgOnChange = (event) => {
    let msg = event.target.value;
    this.setState({ msg: msg });
  };
  onFormSubmit = (event) => {
    let name = this.state.name;
    let mobile = this.state.mobile;
    let msg = this.state.msg;
    if (name.length === 0) {
      toast.error("Name Required");
      // alert("Name Required");
    } else if (mobile.length === 0) {
      toast.error("Mobile Number Required");
      // alert("Mobile Number Required");
    } else if (!validation.NameRegx.test(name)) {
      toast.error("Invalid Name");
      // alert("Invalid Name");
    } else if (!validation.MobileRegx.test(mobile)) {
      toast.error("Invalid Mobile Number");
      // alert("Invalid Mobile Number");
    } else if (msg.length === 0) {
      toast.error("Please Write Your Message");
      // alert("Please Write Your Message");
    } else {
      // alert("ok");
            let sendBtn=document.getElementById('sendBtn');
            let contactForm=document.getElementById('contactForm');
            sendBtn.innerHTML="Sending...";
           let MyFormData=new FormData();
            MyFormData.append("name",name)
            MyFormData.append("mobile",mobile)
            MyFormData.append("msg",msg)

            axios.post(ApiURL.SendContactDetails,MyFormData).then(function (response) {
              if(response.status===200 && response.data===1){
                toast.success("ok")
                //  alert("ok")
                 sendBtn.innerHTML="Send";
                 contactForm.reset();
              }
              else{
                toast.error("error")
                //  alert("error")
                 sendBtn.innerHTML="Send";
              }
          }).catch(function (error) {
            toast.error("error")
            // alert("error")
            sendBtn.innerHTML="Send";
          })
      }
    
    event.preventDefault();
  };
  render() {
    return (
      <Fragment>
        <Container fluid={true} className="TopSection">
          <Row className="d-flex p-1 justify-content-center">
            <Col
              className="shadow-sm bg-white mt-2"
              md={6}
              lg={6}
              sm={8}
              xs={12}
            >
              <Card>
                <Card.Body>
                  <Form id="contactForm" onSubmit={this.onFormSubmit}>
                    <h4 className="section-title">Contact With Us</h4>
                    <Form.Label>Please Enter Your Name</Form.Label>
                    <Form.Control
                      onChange={this.nameOnChange}
                      type="name"
                      placeholder="Enter Your Name"
                    />
                    <Form.Label>Please Enter Your Mobile Number</Form.Label>
                    <Form.Control
                      onChange={this.mobileOnChange}
                      type="mobile"
                      placeholder="your mobile number"
                    />
                    <Form.Label>Please Enter Your Message</Form.Label>
                    <Form.Control
                      onChange={this.msgOnChange}
                      type="msg"
                      placeholder="your message"
                    />
                    <Button id="sendBtn"
                      type="submit"
                      className="btn btn-block m-2 site-btn"
                    >
                      SEND
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>

            <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
              <iframe
                className="GoogleMap"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5018.4459771508045!2d90.41755275313513!3d23.727453383182855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9b0faef7c85%3A0x2aba50dabe58a5c!2sAmin%20Court!5e0!3m2!1sen!2sbd!4v1675269919495!5m2!1sen!2sbd"
              ></iframe>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </Fragment>
    );
  }
}

export default Contact;
