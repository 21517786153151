import React, { Component, Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";
import {Link} from "react-router-dom"; 
class FooterMobile extends Component {
  render() {
    return (
      <Fragment>
        <div className="m-0 bg-white pt-4 shadow-sm" >
          <Container fluid={true}>
            <Row className="px-0 my-5">
              <Col className="p-2" lg={3} md={3} sm={6} xm={6}>
                <h5 className="footer-menu-title">ABOUT COMPANY</h5>
                <p>
                  Big Exppress is considered to be the game changer in the
                  bangladesh industry
                </p>
                <h5 className="footer-menu-title">SOCIAL LINK</h5>
                <a href="https://www.google.com/">
                  <i className="fab m-1 h4 fa-facebook" />
                </a>
                <a href="https://www.google.com/">
                  <i className="fab m-1 h4 fa-instagram" />
                </a>
                <a href="https://www.google.com/">
                  <i className="fab m-1 h4 fa-twitter" />
                </a>
              </Col>
              <Col className="p-2" lg={3} md={3} sm={6} xm={6}>
                <h5 className="footer-menu-title">THE COMPANY</h5>
                <a className="footer-link">About Us</a>
                <br />
                <a className="footer-link">Contact Us</a>
                <br />
                <h5 className="footer-menu-title mt-3">OFFICE ADDRESS</h5>
              </Col>
              <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
                                    <h5 className="footer-menu-title">MORE INFO</h5>
                                    <Link to="/purchase"  className="footer-link">How To Purchase</Link><br/>
                                    <Link to="/policy"  className="footer-link">Privacy Policy</Link><br/>
                                    <Link to="/refund"  className="footer-link">Refund Policy</Link><br/>
                                </Col>
                                <div id="google_translate_element"></div>
            </Row>
          </Container>

          <Container fluid={true} className=" m-0 pt-3 pb-1 bg-dark">
            <Container className="">
              <Row className="px-0 text-white">
                <p>
                
                  Big Exppress is considered to be the game changer in the
                  bangladesh industry
                </p>
              </Row>
            </Container>
          
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default FooterMobile;
