import React, {Component, Fragment} from 'react';
import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";
import axios from "axios";
// import ReactHtmlParser from 'react-html-parser';
import ReactHtmlParser from "html-react-parser";
import ApiURL from "../../api/ApiURL";
// import DescriptionPlaceholder from "../placeholder/DescriptionPlaceholder";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Refund extends Component {

    constructor() {
        super();
        this.state={
          terms:"",
            loaderDiv:"",
            mainDiv:"d-none",
        }
    }
    componentDidMount() {
      console.log("componentDidMount called");
      let SiteInfoterms = sessionStorage.getItem("SiteInfoterms");
      if (SiteInfoterms == null) {
        axios
          .get(ApiURL.SendSiteInfo)
          .then((response) => {
            let StatusCode = response.status;
            if (StatusCode === 200) {
              let JSONData = response.data[0]["terms"];
              this.setState({
                terms: JSONData,
                loaderDiv: "d-none",
                mainDiv: ""
              });
              sessionStorage.setItem("SiteInfoterms", JSONData);
            }
            else{
              toast.error("Something Went Wrong! Try Again",{position:"bottom-center"});
            }
          }).catch((error)=> {
            toast.error("Something Went Wrong! Try Again",{position:"bottom-center"});
          });
      } else {
        this.setState({ terms: SiteInfoterms, loaderDiv: "d-none",
        mainDiv: ""});
      }
    }

    render() {
      return (
        <Fragment>
          <Container fluid={true} className="TopSection">
            <Row className="d-flex p-1 justify-content-center">
              <Col
                className="shadow-sm bg-white mt-2"
                md={6}
                lg={6}
                sm={8}
                xs={12}
              >
                <div className="card-body">
                  <Card className={this.state.loaderDiv}>
                    <Card.Body>
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                          </div>
                        </div>
                      </div> 
                      <div class="ph-item">
                        <div class="ph-col-12">
                          <div class="ph-row">
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                            <div className="ph-col-12"></div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
  
                  <Card className={this.state.mainDiv}>
                    <Card.Body>
                      {/* <DescriptionPlaceholder isLoading={this.state.loaderDiv} /> */}
                      {/* <div className={this.state.mainDiv}> */}
                        <div className="animated zoomIn">
                          {ReactHtmlParser(this.state.terms)}
                        </div>
                      {/* </div> */}
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
            <ToastContainer />
          </Container>
        </Fragment>
      );
    }
}
export default Refund;