import React, {Component, Fragment} from 'react';
import {Route, Routes} from "react-router";
import HomePage from "../pages/HomePage";
import UserOnboardPage from "../pages/UserOnboardPage";
import ContactPage from "../pages/ContactPage";
import PurchasePage from "../pages/PurchasePage";
import PolicyPage from '../pages/PolicyPage';
import RefundPage from '../pages/RefundPage';
import AboutPage from '../pages/AboutPage';
import ProductDetailsPage from '../pages/ProductDetailsPage';
import NotificationPage from '../pages/NotificationPage';
import FavouritePage from '../pages/FavouritePage';
import CartPage from '../pages/CartPage';
import OrderPage from '../pages/OrderPage';

class AppRoute extends Component {
    render() {
        return (
            <Fragment>
                <Routes>
                    <Route exact path="/" element={<HomePage/>} />
                    <Route exact path="/onboard" element={<UserOnboardPage/>} />
                    <Route exact path="/contact" element={<ContactPage/>} />
                    <Route exact path="/purchase" element={<PurchasePage/>} />
                    <Route exact path="/policy" element={<PolicyPage/>} />
                    <Route exact path="/refund" element={<RefundPage/>} />
                    <Route exact path="/about" element={<AboutPage/>} />
                    <Route exact path="/productDetails" element={<ProductDetailsPage/>} />
                    <Route exact path="/notification" element={<NotificationPage/>} />
                    <Route exact path="/favourite" element={<FavouritePage/>} />
                    <Route exact path="/cart" element={<CartPage/>} />
                    <Route exact path="/order" element={<OrderPage/>} />
                </Routes>
            </Fragment>
        );
    }
}

export default AppRoute;