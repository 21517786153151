import React, { Component,Fragment } from 'react'

import NavMenuMobile from "../components/common/NavMenuMobile";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import FooterDesktop from "../components/common/FooterDesktop";
import FooterMobile from "../components/common/FooterMobile";
import UserOnboard from "../components/common/UserOnboard";
export default class UserOnboardPage extends Component {
  componentDidMount() {
    window.scroll(0,0)
}
  render() {
    return (
      <Fragment>
         <div className="Desktop">
          <NavMenuDesktop />
         
        </div>
        <div className="Mobile">
          <NavMenuMobile />
          
        </div>

        <UserOnboard />
       
        <div className="Desktop"><FooterDesktop /></div>
        <div className="Mobile"><FooterMobile /></div>
      </Fragment>
    )
  }
}
