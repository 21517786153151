import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class FeaturedProducts extends Component {
    render() {
        return (
            <Fragment>
                <Container className="text-center" fluid="true">
                    <h4 className="section-title">FEATURED PRODUCTS</h4>
                    <h6 className="section-sub-title">Some Of Our Exclusive Collection, You May Like</h6>
                    <Row>
                        <Col key={1} xl={2} lg={2} md={2} sm={4} xs={6} >
                            <Link to="/productDetails"><Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card></Link>
                            
                        </Col>
                        <Col key={2} xl={2} lg={2} md={2} sm={4} xs={6} >
                        <Link to="/productDetails">
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
                        <Col key={3} xl={2} lg={2} md={2} sm={4} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={4} xl={2} lg={2} md={2} sm={4} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={5} xl={2} lg={2} md={2} sm={4} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={6} xl={2} lg={2} md={2} sm={4} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col>


                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default FeaturedProducts;