import React, {Component, Fragment} from 'react';
import {Container,Row,Col,Card} from "react-bootstrap";
import { Link } from "react-router-dom";

class SuggestedProducts extends Component {

    componentDidMount() {
        window.scroll(0,0)
    }


    render() {

        return (
                <Fragment>
                    <Container className="text-center BetweenTwoSection">
            <h4 className="section-title">You May Like</h4>
            <h6 className="section-sub-title pb-3">Some of Our Exclusive Collection, You May Like</h6>
            <Row>
                <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Link to="/details">
                        <Card className="card w-100 image-box ">
                        <img src="images/product2.jpg"/>
                        <Card.Body>
                            <h5 className="product-name-on-card">Asus Tue A1s FA506IU RYzen 74800H</h5>
                            <p className="product-price-on-card">Price: 3000TK</p>
                        </Card.Body>
                        </Card>
                  </Link>
                </Col>
                <Col className="p-1" key={2} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="card w-100 image-box ">
                        <img src="images/product1.jpg"/>
                        <Card.Body>
                            <h5 className="product-name-on-card">Asus Tue A1s FA506IU RYzen 74800H</h5>
                            <p className="product-price-on-card">Price: 3000TK</p>
                        </Card.Body>
                        </Card>
                </Col>
                <Col className="p-1" key={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="card w-100 image-box ">
                        <img src="images/product3.jpg"/>
                        <Card.Body>
                            <h5 className="product-name-on-card">Asus Tue A1s FA506IU RYzen 74800H</h5>
                            <p className="product-price-on-card">Price: 3000TK</p>
                        </Card.Body>
                        </Card>
                </Col>
                <Col className="p-1" key={4} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="card w-100 image-box ">
                        <img src="images/product4.jpg"/>
                        <Card.Body>
                            <h5 className="product-name-on-card">Asus Tue A1s FA506IU RYzen 74800H</h5>
                            <p className="product-price-on-card">Price: 3000TK</p>
                        </Card.Body>
                        </Card>
                </Col> <Col className="p-1" key={5} xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Link to="/details">
                        <Card className="card w-100 image-box ">
                        <img src="images/product2.jpg"/>
                        <Card.Body>
                            <h5 className="product-name-on-card">Asus Tue A1s FA506IU RYzen 74800H</h5>
                            <p className="product-price-on-card">Price: 3000TK</p>
                        </Card.Body>
                        </Card>
                  </Link>
                </Col>
                <Col className="p-1" key={6} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="card w-100 image-box ">
                        <img src="images/product1.jpg"/>
                        <Card.Body>
                            <h5 className="product-name-on-card">Asus Tue A1s FA506IU RYzen 74800H</h5>
                            <p className="product-price-on-card">Price: 3000TK</p>
                        </Card.Body>
                        </Card>
                </Col>
                <Col className="p-1" key={7} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="card w-100 image-box ">
                        <img src="images/product3.jpg"/>
                        <Card.Body>
                            <h5 className="product-name-on-card">Asus Tue A1s FA506IU RYzen 74800H</h5>
                            <p className="product-price-on-card">Price: 3000TK</p>
                        </Card.Body>
                        </Card>
                </Col>
                <Col className="p-1" key={8} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="card w-100 image-box ">
                        <img src="images/product4.jpg"/>
                        <Card.Body>
                            <h5 className="product-name-on-card">Asus Tue A1s FA506IU RYzen 74800H</h5>
                            <p className="product-price-on-card">Price: 3000TK</p>
                        </Card.Body>
                        </Card>
                </Col>
            </Row>

        </Container>
                </Fragment>
            );
        }
    
    
}

export default SuggestedProducts;