import React, { Component, Fragment } from "react";
import { Button, Container, Row, Col, Breadcrumb, Card } from "react-bootstrap";
// import ReactHtmlParser from "react-html-parser";
// import InnerImageZoom from 'react-inner-image-zoom';
// import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
// import SuggestedProducts from "./SuggestedProducts";
// import ReviewList from "./ReviewList";
import { Link } from "react-router-dom";
// import LocalStorageHelper from "../../LocalStorageHelper/LocalStorageHelper";
// import cogoToast from "cogo-toast";
// import axios from "axios";
// import ApiURL from "../../api/ApiURL";
import { Redirect } from "react-router";
class ProductDetails extends Component {
  render() {
    return (
      <Fragment>
        <Container className="BetweenTwoSection">
          <Row className="p-1">
            <Col
              className="shadow-sm bg-white pb-3 mt-4"
              md={12}
              lg={12}
              sm={12}
              xs={12}
            >
              <Row>
                <Col className="p-3" md={6} lg={6} sm={12} xs={12}>
                  <img className="w-100" src="images/img2.jpg" alt="" />
                  <Container className="my-3">
                    <Row>
                      <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
                        <img className="w-100" src="images/img2.jpg" alt="" />
                      </Col>
                      <Col className="p-0  m-0" md={3} lg={3} sm={3} xs={3}>
                        <img className="w-100 " src="images/img3.jpg" alt="" />
                      </Col>
                      <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
                        <img className="w-100 " src="images/img4.jpg" alt="" />
                      </Col>
                      <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
                        <img className="w-100 " src="images/img4.jpg" alt="" />
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col className="p-3 " md={6} lg={6} sm={12} xs={12}>
                  <h5 className="Product-Name">
                    ASUS TUF A15 FA506IU Ryzen 7 4800H GTX
                  </h5>
                  <h6 className="section-sub-title">
                    Some of Our Exclusive Collection, You May
                  </h6>

                  <div className="input-group">
                    <div className="Product-price-card d-inline ">
                      Regular Price 200
                    </div>
                    <div className="Product-price-card d-inline ">
                      50 % Discount
                    </div>
                    <div className="Product-price-card d-inline ">
                      New Price 100
                    </div>
                  </div>

                  <h6 className="mt-2">Choose Color</h6>
                  <div className="input-group">
                    <div className="form-check mx-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Black
                      </label>
                    </div>
                    <div className="form-check mx-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Green
                      </label>
                    </div>
                    <div className="form-check mx-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Red
                      </label>
                    </div>
                  </div>

                  <h6 className="mt-2">Choose Size</h6>
                  <div className="input-group">
                    <div className="form-check mx-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        X
                      </label>
                    </div>
                    <div className="form-check mx-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        XL
                      </label>
                    </div>
                    <div className="form-check mx-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        XXL
                      </label>
                    </div>
                  </div>

                  <h6 className="mt-2">Choose Quantity</h6>
                  <input
                    className="form-control text-center w-50"
                    type="number"
                  />

                  <div className="input-group mt-3">
                    <Button className="btn site-btn m-1">
                      <i className="fa fa-shopping-cart" />
                      Add to Cart
                    </Button>
                    <Button className="btn btn-primary m-1">
                      <i className="fa fa-car" />
                      Order Now
                    </Button>
                    <Button className="btn btn-primary m-1">
                      <i className="fa fa-heart" />
                      Favourite
                    </Button>
                  </div>

                  <div className="" md={6} lg={6} sm={12} xs={12}>
                      <h6 className="mt-2">REVIEWS</h6>
                      <p className="p-0 m-0">
                        <span className="Review-Title">Rabbil Hasan</span>
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                      <p className="p-0 m-0">
                        <span className="Review-Title">Rabbil Hasan</span>
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                      <p className="p-0 m-0">
                        <span className="Review-Title">Rabbil Hasan</span>
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                </Col>
              </Row>
              <Row>
                <Col className="" md={6} lg={6} sm={12} xs={12}>
                  <Row>
                    <Col className="" md={6} lg={6} sm={12} xs={12}>
                      <h6 className="mt-2">Details</h6>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Maxime mollitia, molestiae quas vel sint commodi
                        repudiandae consequuntur voluptatum laborum numquam
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Maxime mollitia, molestiae quas vel sint commodi
                        repudiandae consequuntur voluptatum laborum numquam
                      </p>
                    </Col>
                   
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        
      </Fragment>
    );
  }
}
export default ProductDetails;
