import React, { Component, Fragment } from "react";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Breadcrumb,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
class Notification extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  constructor() {
    super();
    this.state = {
      show: false,
    };

    this.handleClose = () => {
      this.setState({ show: false });
    };
    this.handleShow = () => {
      this.setState({ show: true });
    };
  }
  render() {
    return (
      <Fragment>
        <Container className="TopSection">
          <Row>
            <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
              <Card onClick={this.handleShow} className="notification-card">
                <Card.Body>
                  <h6>Loren Ipsum is simply dummy text of the printing</h6>
                  <p className="py-1 px-0 text-primary m-0">
                    <i className="fa fa-bell"></i> Date:22/02/2023 |
                    Status:Unread
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
              <Card onClick={this.handleShow} className="notification-card">
                <Card.Body>
                  <h6>Loren Ipsum is simply dummy text of the printing</h6>
                  <p className="py-1 px-0 text-primary m-0">
                    <i className="fa fa-bell"></i> Date:22/02/2023 |
                    Status:Unread
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
              <Card onClick={this.handleShow} className="notification-card">
                <Card.Body>
                  <h6>Loren Ipsum is simply dummy text of the printing</h6>
                  <p className="py-1 px-0 text-primary m-0">
                    <i className="fa fa-bell"></i> Date:22/02/2023 | Status:Read
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
              <Card onClick={this.handleShow} className="notification-card">
                <Card.Body>
                  <h6>Loren Ipsum is simply dummy text of the printing</h6>
                  <p className="py-1 px-0 text-primary m-0">
                    <i className="fa fa-bell"></i> Date:22/02/2023 | Status:Read
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
              <Card onClick={this.handleShow} className="notification-card">
                <Card.Body>
                  <h6>Loren Ipsum is simply dummy text of the printing</h6>
                  <p className="py-1 px-0 text-primary m-0">
                    <i className="fa fa-bell"></i> Date:22/02/2023 | Status:Read
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
              <Card onClick={this.handleShow} className="notification-card">
                <Card.Body>
                  <h6>Loren Ipsum is simply dummy text of the printing</h6>
                  <p className="py-1 px-0 text-primary m-0">
                    <i className="fa fa-bell"></i> Date:22/02/2023 | Status:Read
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* <Container className="TopSection">


                        <Row  className="d-flex justify-content-center">
                            <Col  md={10} lg={10}  sm={12} xs={12}>
                                <Breadcrumb className="shadow-sm mt-2 bg-white">
                                    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item><Link to="/notification">Notification</Link></Breadcrumb.Item>
                                </Breadcrumb>
                              <Container className="mt-1">
                                  <Row className="shadow-sm animated slideInDown bg-white p-4">
                                  
                                  </Row>
                              </Container>
                          </Col>
                        </Row>
                    </Container> */}

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <h6>
             
              <i className="fa fa-bell" /> Date: 22/02/2023
            </h6>
          </Modal.Header>
          <Modal.Body>
            <h6> lorem ipsum is </h6>
            <p>lorem ipsum is</p>
          </Modal.Body>
          <Modal.Footer>
            <button varient="secondary" onClick={this.handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default Notification;
