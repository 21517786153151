import React, {Component, Fragment} from 'react';
import Slider from "react-slick";
import {Card, Col, Container, Row} from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
class NewArrival extends Component {
    constructor(props) {
        super(props);
        this.next=this.next.bind(this);
        this.previous=this.previous.bind(this)
    }

    next(){
        this.slider.slickNext();
    }
    previous(){
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplaySpeed:3000,
            autoplay:true,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Container className="text-center" fluid="true">
                <h4 className="section-title">NEW ARRIVAL
                <a className="btn btn-sm ml-2 site-btn" onClick={this.previous}>
                    <i className="fa fa-angle-left"></i>
                </a>
                    <a className="btn btn-sm ml-2 site-btn" onClick={this.next}>
                    <i className="fa fa-angle-right"></i>
                </a>
                </h4>
                <h6 className="section-sub-title">Some Of Our Exclusive Collection, You May Like</h6>



                        <Slider ref={c=>(this.slider=c)} {...settings}>
                            <div>
                                <Card className="image-box card text-center">
                                        <img src="images/product1.jpg"/>
                                        <Card.Body>
                                            <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                            <p className="product-price-on-card">৳ 9,691</p>
                                        </Card.Body>
                                    </Card>
                            </div>
                            <div>
                                <Card className="image-box card text-center">
                                    <img src="images/product1.jpg"/>
                                    <Card.Body>
                                        <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                        <p className="product-price-on-card">৳ 9,691</p>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card className="image-box card text-center">
                                    <img src="images/product1.jpg"/>
                                    <Card.Body>
                                        <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                        <p className="product-price-on-card">৳ 9,691</p>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card className="image-box card text-center">
                                    <img src="images/product1.jpg"/>
                                    <Card.Body>
                                        <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                        <p className="product-price-on-card">৳ 9,691</p>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div>
                                <Card className="image-box card text-center">
                                    <img src="images/product1.jpg"/>
                                    <Card.Body>
                                        <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                        <p className="product-price-on-card">৳ 9,691</p>
                                    </Card.Body>
                                </Card>
                            </div>

                        </Slider>


            </Container>
        );
    }
}

export default NewArrival;