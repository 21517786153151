import React, { Component, Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import MegaMenuMobile from "../home/MegaMenuMobile";
import {Link} from "react-router-dom"; 

class NavMenuMobile extends Component {
    constructor() {
        super();
        this.state={
            SideNavState:"sideNavClose",
            ContentOverState:"ContentOverlayClose",
            MenuData:[],
            RedirectHome:false,
            cartCount:0
        }
    }

    MenuBarClickHandler=()=>{
        this.SideNavOpenClose();
    }
    ContentOverlayClickHandler=()=>{
        this.SideNavOpenClose();
    }
    SideNavOpenClose=()=>{
       let SideNavState= this.state.SideNavState;
       let ContentOverState= this.state.ContentOverState;
       if(SideNavState==="sideNavOpen"){
           this.setState({SideNavState:"sideNavClose",ContentOverState:"ContentOverlayClose"})
       }
       else{
           this.setState({SideNavState:"sideNavOpen",ContentOverState:"ContentOverlayOpen"})
       }
    }
    render() {
        return (
            <Fragment>
                <Container
                    fluid={"true"}
                    className="fixed-top shadow-sm p-2 m-0 bg-white"
                >
                    <Row>
                        <Col className="p-1" lg={12} md={12} sm={12} xs={12}>
                            <Button onClick={this.MenuBarClickHandler} className="btn">
                                <i className="fa fa-bars"></i>
                            </Button>
                      
                            <Link
                                 to="/"
                                className="btn"
                            >
                                <img
                                    className="nav-logo"
                                    src="images/logo.png"
                                    alt="img"
                                />
                            </Link>

                            <Link  to="/cart" button className="cart-btn">
                                <i className="fa fa-shoping-cart" />
                                <i /> 4 items
                            </Link>
                     
                                <Link to="/favourite" key={1} className="btn"><i className="fa h4 fa-heart"></i></Link>
                                <Link to="/notification" key={2} className="btn"><i className="fa h4 fa-bell"></i></Link>
                                <a key={3} className="btn"><i className="fa h4 fa-mobile-alt"></i></a>
                                <Link to="/onboard"className="h4 btn">LOGIN</Link>
                            <button type="button" className="btn  site-btn"><i className="fa fa-search"/><i/></button>
                            </Col>
                    </Row>
                </Container>

                <div className={this.state.SideNavState}>
                <MegaMenuMobile/>
                </div>
                
                <div
                    onClick={this.ContentOverlayClickHandler}
                    className={this.state.ContentOverState}
                >

                    
                </div>
            </Fragment>
        );
    }
}

export default NavMenuMobile;
