import React, {Component, Fragment} from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";

class Categories extends Component {
    render() {
        return (
            <Fragment>
                <Container className="text-center" fluid="true">
                    <h4 className="section-title">CATEGORIES</h4>
                    <h6 className="section-sub-title">Some Of Our Exclusive Collection, You May Like</h6>
                    <Row>
                        <Col key={0} xl={6} lg={6} md={6} sm={12} xs={12} >
                       <Row>
                           <Col p1={0}  key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={2} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={3} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={4} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="https://static-01.daraz.com.bd/p/7481fa0c908359827ce088fcddbd8d12.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                       </Row>
                        </Col>
                        <Col key={5} xl={6} lg={6} md={6} sm={12} xs={12} >
                       <Row>
                           <Col p1={0}  key={11} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={6} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={7} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={8} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                       </Row>
                        </Col>
                        <Col key={10} xl={6} lg={6} md={6} sm={12} xs={12} >
                       <Row>
                           <Col p1={0}  key={22} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={11} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={12} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={13} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                       </Row>
                        </Col>
                        <Col key={14} xl={6} lg={6} md={6} sm={12} xs={12} >
                       <Row>
                           <Col p1={0}  key={33} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={15} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={16} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                           <Col p1={0} key={17} xl={3} lg={3} md={3} sm={6} xs={6} >
                               <Card className="image-box card">
                                   <img src="images/product2.jpg"/>
                                   <Card.Body>
                                       <h5 className="category-name">CATEGORY NAME</h5>
                                   </Card.Body>
                               </Card>
                           </Col>
                       </Row>
                        </Col>


                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Categories;
