import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
class Collection extends Component {
    render() {
        return (
            <Fragment>
                <Container className="text-center card" fluid="true">
                    <h4 className="section-title">Special Collections</h4>
                    <h6 className="section-sub-title">Some Of Our Exclusive Collection, You May Like</h6>
                    <Row>
                        <Col key={0} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col>   <Col key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={2} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col>   <Col key={3} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col><Col key={4} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col><Col key={5} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col><Col key={6} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col><Col key={7} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Card className="image-box card text-center">
                                <img src="images/product2.jpg"/>
                                <Card.Body>
                                    <p className="product-name-on-card">Redmi A1 2GB/32GB</p>
                                    <p className="product-price-on-card">৳ 9,691</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Collection;