import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom"; 
class NavMenuDesktop extends Component {
    render() {
        return (
            <Fragment>
                    <Container fluid={"true"} className="fixed-top shadow-sm p-2 m-0 bg-white">
                        <Row>
                            <Col lg={4} md={4} sm={12} xs={12}>
                            <Link
                                to="/"
                                className="btn"
                            >
                                <img
                                    className="nav-logo"
                                    src="images/logo.png"
                                    alt="img"
                                />
                            </Link>
                                <Link to="/cart" type="button" className="cart-btn"><i className="fa fa-shopping-cart"/> 4 items </Link>
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={12}><div className="input-group w-100">
                                <input type="text" name="example" list="exampleList"  className="form-control" aria-label="Text input with segmented dropdown button"/>
                                <button type="button" className="btn  site-btn"><i className="fa fa-search"/><i/></button>
                            </div></Col>
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <Link to="/favourite" key={1} className="btn"><i className="fa h4 fa-heart"></i></Link>
                                <Link to="/notification" key={2} className="btn"><i className="fa h4 fa-bell"></i></Link>
                                <a key={3} className="btn"><i className="fa h4 fa-mobile-alt"></i></a>
                                <Link to="/onboard"className="h4 btn">LOGIN</Link>
                            </Col>
                        </Row>
                    </Container>


            </Fragment>
        );
    }
}

export default NavMenuDesktop;